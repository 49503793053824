import api from '@motionelements/core/src/api/base-api-v2.js';

// eslint-disable-next-line
export const listFaqs = async (type) => api.get('/v2/faqs', {
  withCredentials: false,
  params: {
    type,
    ...api.getLocaleParams(),
  },
});
