import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _316cc593 = () => interopDefault(import('../src/pages/about.vue' /* webpackChunkName: "pages/about" */))
const _00fbb009 = () => interopDefault(import('../src/pages/ai-scriptwriter.vue' /* webpackChunkName: "pages/ai-scriptwriter" */))
const _7b5c2004 = () => interopDefault(import('../src/pages/audiosearch.vue' /* webpackChunkName: "pages/audiosearch" */))
const _41542caf = () => interopDefault(import('../src/pages/begin.vue' /* webpackChunkName: "pages/begin" */))
const _5259608d = () => interopDefault(import('../src/pages/brand.vue' /* webpackChunkName: "pages/brand" */))
const _54d9baab = () => interopDefault(import('../src/pages/calendar/index.vue' /* webpackChunkName: "pages/calendar/index" */))
const _81e9b530 = () => interopDefault(import('../src/pages/call-for-submission/index.vue' /* webpackChunkName: "pages/call-for-submission/index" */))
const _32b33e36 = () => interopDefault(import('../src/pages/contributors/index.vue' /* webpackChunkName: "pages/contributors/index" */))
const _0fdcd76b = () => interopDefault(import('../src/pages/corporate.vue' /* webpackChunkName: "pages/corporate" */))
const _5124a058 = () => interopDefault(import('../src/pages/index.vue' /* webpackChunkName: "pages/index" */))
const _46246bfc = () => interopDefault(import('../src/pages/legal/index.vue' /* webpackChunkName: "pages/legal/index" */))
const _6328e8c7 = () => interopDefault(import('../src/pages/license.vue' /* webpackChunkName: "pages/license" */))
const _33848c68 = () => interopDefault(import('../src/pages/pricing.vue' /* webpackChunkName: "pages/pricing" */))
const _207bab07 = () => interopDefault(import('../src/pages/referral.vue' /* webpackChunkName: "pages/referral" */))
const _a4b7ec38 = () => interopDefault(import('../src/pages/referral/banner.vue' /* webpackChunkName: "pages/referral/banner" */))
const _77851412 = () => interopDefault(import('../src/pages/referral/text-link.vue' /* webpackChunkName: "pages/referral/text-link" */))
const _4a0db1d0 = () => interopDefault(import('../src/pages/subscribe.vue' /* webpackChunkName: "pages/subscribe" */))
const _022915d2 = () => interopDefault(import('../src/pages/visualsearch.vue' /* webpackChunkName: "pages/visualsearch" */))
const _66deeec4 = () => interopDefault(import('../src/pages/call-for-submission/_channel.vue' /* webpackChunkName: "pages/call-for-submission/_channel" */))
const _58f47fa5 = () => interopDefault(import('../src/pages/campaign/_id.vue' /* webpackChunkName: "pages/campaign/_id" */))
const _3172d348 = () => interopDefault(import('../src/pages/contributors/_username.vue' /* webpackChunkName: "pages/contributors/_username" */))
const _581eef0d = () => interopDefault(import('../src/pages/free/_channel.vue' /* webpackChunkName: "pages/free/_channel" */))
const _3cdbf8ac = () => interopDefault(import('../src/pages/legal/_document.vue' /* webpackChunkName: "pages/legal/_document" */))
const _e08c30a4 = () => interopDefault(import('../src/pages/_channel.vue' /* webpackChunkName: "pages/_channel" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about",
    component: _316cc593,
    name: "about___en"
  }, {
    path: "/ai-scriptwriter",
    component: _00fbb009,
    name: "ai-scriptwriter___en"
  }, {
    path: "/audiosearch",
    component: _7b5c2004,
    name: "audiosearch___en"
  }, {
    path: "/begin",
    component: _41542caf,
    name: "begin___en"
  }, {
    path: "/brand",
    component: _5259608d,
    name: "brand___en"
  }, {
    path: "/calendar",
    component: _54d9baab,
    name: "calendar___en"
  }, {
    path: "/call-for-submission",
    component: _81e9b530,
    name: "call-for-submission___en"
  }, {
    path: "/contributors",
    component: _32b33e36,
    name: "contributors___en"
  }, {
    path: "/corporate",
    component: _0fdcd76b,
    name: "corporate___en"
  }, {
    path: "/da",
    component: _5124a058,
    name: "index___da"
  }, {
    path: "/de",
    component: _5124a058,
    name: "index___de"
  }, {
    path: "/es",
    component: _5124a058,
    name: "index___es"
  }, {
    path: "/fr",
    component: _5124a058,
    name: "index___fr"
  }, {
    path: "/hu",
    component: _5124a058,
    name: "index___hu"
  }, {
    path: "/it",
    component: _5124a058,
    name: "index___it"
  }, {
    path: "/ja",
    component: _5124a058,
    name: "index___ja"
  }, {
    path: "/ko",
    component: _5124a058,
    name: "index___ko"
  }, {
    path: "/legal",
    component: _46246bfc,
    name: "legal___en"
  }, {
    path: "/license",
    component: _6328e8c7,
    name: "license___en"
  }, {
    path: "/nb",
    component: _5124a058,
    name: "index___nb"
  }, {
    path: "/nl",
    component: _5124a058,
    name: "index___nl"
  }, {
    path: "/pl",
    component: _5124a058,
    name: "index___pl"
  }, {
    path: "/pricing",
    component: _33848c68,
    name: "pricing___en"
  }, {
    path: "/pt",
    component: _5124a058,
    name: "index___pt"
  }, {
    path: "/referral",
    component: _207bab07,
    name: "referral___en",
    children: [{
      path: "banner",
      component: _a4b7ec38,
      name: "referral-banner___en"
    }, {
      path: "text-link",
      component: _77851412,
      name: "referral-text-link___en"
    }]
  }, {
    path: "/ru",
    component: _5124a058,
    name: "index___ru"
  }, {
    path: "/subscribe",
    component: _4a0db1d0,
    name: "subscribe___en"
  }, {
    path: "/sv",
    component: _5124a058,
    name: "index___sv"
  }, {
    path: "/th",
    component: _5124a058,
    name: "index___th"
  }, {
    path: "/tr",
    component: _5124a058,
    name: "index___tr"
  }, {
    path: "/visualsearch",
    component: _022915d2,
    name: "visualsearch___en"
  }, {
    path: "/zh-hans",
    component: _5124a058,
    name: "index___zh-hans"
  }, {
    path: "/zh-hant",
    component: _5124a058,
    name: "index___zh-hant"
  }, {
    path: "/da/about",
    component: _316cc593,
    name: "about___da"
  }, {
    path: "/da/ai-scriptwriter",
    component: _00fbb009,
    name: "ai-scriptwriter___da"
  }, {
    path: "/da/audiosearch",
    component: _7b5c2004,
    name: "audiosearch___da"
  }, {
    path: "/da/begin",
    component: _41542caf,
    name: "begin___da"
  }, {
    path: "/da/brand",
    component: _5259608d,
    name: "brand___da"
  }, {
    path: "/da/calendar",
    component: _54d9baab,
    name: "calendar___da"
  }, {
    path: "/da/call-for-submission",
    component: _81e9b530,
    name: "call-for-submission___da"
  }, {
    path: "/da/contributors",
    component: _32b33e36,
    name: "contributors___da"
  }, {
    path: "/da/corporate",
    component: _0fdcd76b,
    name: "corporate___da"
  }, {
    path: "/da/legal",
    component: _46246bfc,
    name: "legal___da"
  }, {
    path: "/da/license",
    component: _6328e8c7,
    name: "license___da"
  }, {
    path: "/da/pricing",
    component: _33848c68,
    name: "pricing___da"
  }, {
    path: "/da/referral",
    component: _207bab07,
    name: "referral___da",
    children: [{
      path: "banner",
      component: _a4b7ec38,
      name: "referral-banner___da"
    }, {
      path: "text-link",
      component: _77851412,
      name: "referral-text-link___da"
    }]
  }, {
    path: "/da/subscribe",
    component: _4a0db1d0,
    name: "subscribe___da"
  }, {
    path: "/da/visualsearch",
    component: _022915d2,
    name: "visualsearch___da"
  }, {
    path: "/de/about",
    component: _316cc593,
    name: "about___de"
  }, {
    path: "/de/ai-scriptwriter",
    component: _00fbb009,
    name: "ai-scriptwriter___de"
  }, {
    path: "/de/audiosearch",
    component: _7b5c2004,
    name: "audiosearch___de"
  }, {
    path: "/de/begin",
    component: _41542caf,
    name: "begin___de"
  }, {
    path: "/de/brand",
    component: _5259608d,
    name: "brand___de"
  }, {
    path: "/de/calendar",
    component: _54d9baab,
    name: "calendar___de"
  }, {
    path: "/de/call-for-submission",
    component: _81e9b530,
    name: "call-for-submission___de"
  }, {
    path: "/de/contributors",
    component: _32b33e36,
    name: "contributors___de"
  }, {
    path: "/de/corporate",
    component: _0fdcd76b,
    name: "corporate___de"
  }, {
    path: "/de/legal",
    component: _46246bfc,
    name: "legal___de"
  }, {
    path: "/de/license",
    component: _6328e8c7,
    name: "license___de"
  }, {
    path: "/de/pricing",
    component: _33848c68,
    name: "pricing___de"
  }, {
    path: "/de/referral",
    component: _207bab07,
    name: "referral___de",
    children: [{
      path: "banner",
      component: _a4b7ec38,
      name: "referral-banner___de"
    }, {
      path: "text-link",
      component: _77851412,
      name: "referral-text-link___de"
    }]
  }, {
    path: "/de/subscribe",
    component: _4a0db1d0,
    name: "subscribe___de"
  }, {
    path: "/de/visualsearch",
    component: _022915d2,
    name: "visualsearch___de"
  }, {
    path: "/es/about",
    component: _316cc593,
    name: "about___es"
  }, {
    path: "/es/ai-scriptwriter",
    component: _00fbb009,
    name: "ai-scriptwriter___es"
  }, {
    path: "/es/audiosearch",
    component: _7b5c2004,
    name: "audiosearch___es"
  }, {
    path: "/es/begin",
    component: _41542caf,
    name: "begin___es"
  }, {
    path: "/es/brand",
    component: _5259608d,
    name: "brand___es"
  }, {
    path: "/es/calendar",
    component: _54d9baab,
    name: "calendar___es"
  }, {
    path: "/es/call-for-submission",
    component: _81e9b530,
    name: "call-for-submission___es"
  }, {
    path: "/es/contributors",
    component: _32b33e36,
    name: "contributors___es"
  }, {
    path: "/es/corporate",
    component: _0fdcd76b,
    name: "corporate___es"
  }, {
    path: "/es/legal",
    component: _46246bfc,
    name: "legal___es"
  }, {
    path: "/es/license",
    component: _6328e8c7,
    name: "license___es"
  }, {
    path: "/es/pricing",
    component: _33848c68,
    name: "pricing___es"
  }, {
    path: "/es/referral",
    component: _207bab07,
    name: "referral___es",
    children: [{
      path: "banner",
      component: _a4b7ec38,
      name: "referral-banner___es"
    }, {
      path: "text-link",
      component: _77851412,
      name: "referral-text-link___es"
    }]
  }, {
    path: "/es/subscribe",
    component: _4a0db1d0,
    name: "subscribe___es"
  }, {
    path: "/es/visualsearch",
    component: _022915d2,
    name: "visualsearch___es"
  }, {
    path: "/fr/about",
    component: _316cc593,
    name: "about___fr"
  }, {
    path: "/fr/ai-scriptwriter",
    component: _00fbb009,
    name: "ai-scriptwriter___fr"
  }, {
    path: "/fr/audiosearch",
    component: _7b5c2004,
    name: "audiosearch___fr"
  }, {
    path: "/fr/begin",
    component: _41542caf,
    name: "begin___fr"
  }, {
    path: "/fr/brand",
    component: _5259608d,
    name: "brand___fr"
  }, {
    path: "/fr/calendar",
    component: _54d9baab,
    name: "calendar___fr"
  }, {
    path: "/fr/call-for-submission",
    component: _81e9b530,
    name: "call-for-submission___fr"
  }, {
    path: "/fr/contributors",
    component: _32b33e36,
    name: "contributors___fr"
  }, {
    path: "/fr/corporate",
    component: _0fdcd76b,
    name: "corporate___fr"
  }, {
    path: "/fr/legal",
    component: _46246bfc,
    name: "legal___fr"
  }, {
    path: "/fr/license",
    component: _6328e8c7,
    name: "license___fr"
  }, {
    path: "/fr/pricing",
    component: _33848c68,
    name: "pricing___fr"
  }, {
    path: "/fr/referral",
    component: _207bab07,
    name: "referral___fr",
    children: [{
      path: "banner",
      component: _a4b7ec38,
      name: "referral-banner___fr"
    }, {
      path: "text-link",
      component: _77851412,
      name: "referral-text-link___fr"
    }]
  }, {
    path: "/fr/subscribe",
    component: _4a0db1d0,
    name: "subscribe___fr"
  }, {
    path: "/fr/visualsearch",
    component: _022915d2,
    name: "visualsearch___fr"
  }, {
    path: "/hu/about",
    component: _316cc593,
    name: "about___hu"
  }, {
    path: "/hu/ai-scriptwriter",
    component: _00fbb009,
    name: "ai-scriptwriter___hu"
  }, {
    path: "/hu/audiosearch",
    component: _7b5c2004,
    name: "audiosearch___hu"
  }, {
    path: "/hu/begin",
    component: _41542caf,
    name: "begin___hu"
  }, {
    path: "/hu/brand",
    component: _5259608d,
    name: "brand___hu"
  }, {
    path: "/hu/calendar",
    component: _54d9baab,
    name: "calendar___hu"
  }, {
    path: "/hu/call-for-submission",
    component: _81e9b530,
    name: "call-for-submission___hu"
  }, {
    path: "/hu/contributors",
    component: _32b33e36,
    name: "contributors___hu"
  }, {
    path: "/hu/corporate",
    component: _0fdcd76b,
    name: "corporate___hu"
  }, {
    path: "/hu/legal",
    component: _46246bfc,
    name: "legal___hu"
  }, {
    path: "/hu/license",
    component: _6328e8c7,
    name: "license___hu"
  }, {
    path: "/hu/pricing",
    component: _33848c68,
    name: "pricing___hu"
  }, {
    path: "/hu/referral",
    component: _207bab07,
    name: "referral___hu",
    children: [{
      path: "banner",
      component: _a4b7ec38,
      name: "referral-banner___hu"
    }, {
      path: "text-link",
      component: _77851412,
      name: "referral-text-link___hu"
    }]
  }, {
    path: "/hu/subscribe",
    component: _4a0db1d0,
    name: "subscribe___hu"
  }, {
    path: "/hu/visualsearch",
    component: _022915d2,
    name: "visualsearch___hu"
  }, {
    path: "/it/about",
    component: _316cc593,
    name: "about___it"
  }, {
    path: "/it/ai-scriptwriter",
    component: _00fbb009,
    name: "ai-scriptwriter___it"
  }, {
    path: "/it/audiosearch",
    component: _7b5c2004,
    name: "audiosearch___it"
  }, {
    path: "/it/begin",
    component: _41542caf,
    name: "begin___it"
  }, {
    path: "/it/brand",
    component: _5259608d,
    name: "brand___it"
  }, {
    path: "/it/calendar",
    component: _54d9baab,
    name: "calendar___it"
  }, {
    path: "/it/call-for-submission",
    component: _81e9b530,
    name: "call-for-submission___it"
  }, {
    path: "/it/contributors",
    component: _32b33e36,
    name: "contributors___it"
  }, {
    path: "/it/corporate",
    component: _0fdcd76b,
    name: "corporate___it"
  }, {
    path: "/it/legal",
    component: _46246bfc,
    name: "legal___it"
  }, {
    path: "/it/license",
    component: _6328e8c7,
    name: "license___it"
  }, {
    path: "/it/pricing",
    component: _33848c68,
    name: "pricing___it"
  }, {
    path: "/it/referral",
    component: _207bab07,
    name: "referral___it",
    children: [{
      path: "banner",
      component: _a4b7ec38,
      name: "referral-banner___it"
    }, {
      path: "text-link",
      component: _77851412,
      name: "referral-text-link___it"
    }]
  }, {
    path: "/it/subscribe",
    component: _4a0db1d0,
    name: "subscribe___it"
  }, {
    path: "/it/visualsearch",
    component: _022915d2,
    name: "visualsearch___it"
  }, {
    path: "/ja/about",
    component: _316cc593,
    name: "about___ja"
  }, {
    path: "/ja/ai-scriptwriter",
    component: _00fbb009,
    name: "ai-scriptwriter___ja"
  }, {
    path: "/ja/audiosearch",
    component: _7b5c2004,
    name: "audiosearch___ja"
  }, {
    path: "/ja/begin",
    component: _41542caf,
    name: "begin___ja"
  }, {
    path: "/ja/brand",
    component: _5259608d,
    name: "brand___ja"
  }, {
    path: "/ja/calendar",
    component: _54d9baab,
    name: "calendar___ja"
  }, {
    path: "/ja/call-for-submission",
    component: _81e9b530,
    name: "call-for-submission___ja"
  }, {
    path: "/ja/contributors",
    component: _32b33e36,
    name: "contributors___ja"
  }, {
    path: "/ja/corporate",
    component: _0fdcd76b,
    name: "corporate___ja"
  }, {
    path: "/ja/legal",
    component: _46246bfc,
    name: "legal___ja"
  }, {
    path: "/ja/license",
    component: _6328e8c7,
    name: "license___ja"
  }, {
    path: "/ja/pricing",
    component: _33848c68,
    name: "pricing___ja"
  }, {
    path: "/ja/referral",
    component: _207bab07,
    name: "referral___ja",
    children: [{
      path: "banner",
      component: _a4b7ec38,
      name: "referral-banner___ja"
    }, {
      path: "text-link",
      component: _77851412,
      name: "referral-text-link___ja"
    }]
  }, {
    path: "/ja/subscribe",
    component: _4a0db1d0,
    name: "subscribe___ja"
  }, {
    path: "/ja/visualsearch",
    component: _022915d2,
    name: "visualsearch___ja"
  }, {
    path: "/ko/about",
    component: _316cc593,
    name: "about___ko"
  }, {
    path: "/ko/ai-scriptwriter",
    component: _00fbb009,
    name: "ai-scriptwriter___ko"
  }, {
    path: "/ko/audiosearch",
    component: _7b5c2004,
    name: "audiosearch___ko"
  }, {
    path: "/ko/begin",
    component: _41542caf,
    name: "begin___ko"
  }, {
    path: "/ko/brand",
    component: _5259608d,
    name: "brand___ko"
  }, {
    path: "/ko/calendar",
    component: _54d9baab,
    name: "calendar___ko"
  }, {
    path: "/ko/call-for-submission",
    component: _81e9b530,
    name: "call-for-submission___ko"
  }, {
    path: "/ko/contributors",
    component: _32b33e36,
    name: "contributors___ko"
  }, {
    path: "/ko/corporate",
    component: _0fdcd76b,
    name: "corporate___ko"
  }, {
    path: "/ko/legal",
    component: _46246bfc,
    name: "legal___ko"
  }, {
    path: "/ko/license",
    component: _6328e8c7,
    name: "license___ko"
  }, {
    path: "/ko/pricing",
    component: _33848c68,
    name: "pricing___ko"
  }, {
    path: "/ko/referral",
    component: _207bab07,
    name: "referral___ko",
    children: [{
      path: "banner",
      component: _a4b7ec38,
      name: "referral-banner___ko"
    }, {
      path: "text-link",
      component: _77851412,
      name: "referral-text-link___ko"
    }]
  }, {
    path: "/ko/subscribe",
    component: _4a0db1d0,
    name: "subscribe___ko"
  }, {
    path: "/ko/visualsearch",
    component: _022915d2,
    name: "visualsearch___ko"
  }, {
    path: "/nb/about",
    component: _316cc593,
    name: "about___nb"
  }, {
    path: "/nb/ai-scriptwriter",
    component: _00fbb009,
    name: "ai-scriptwriter___nb"
  }, {
    path: "/nb/audiosearch",
    component: _7b5c2004,
    name: "audiosearch___nb"
  }, {
    path: "/nb/begin",
    component: _41542caf,
    name: "begin___nb"
  }, {
    path: "/nb/brand",
    component: _5259608d,
    name: "brand___nb"
  }, {
    path: "/nb/calendar",
    component: _54d9baab,
    name: "calendar___nb"
  }, {
    path: "/nb/call-for-submission",
    component: _81e9b530,
    name: "call-for-submission___nb"
  }, {
    path: "/nb/contributors",
    component: _32b33e36,
    name: "contributors___nb"
  }, {
    path: "/nb/corporate",
    component: _0fdcd76b,
    name: "corporate___nb"
  }, {
    path: "/nb/legal",
    component: _46246bfc,
    name: "legal___nb"
  }, {
    path: "/nb/license",
    component: _6328e8c7,
    name: "license___nb"
  }, {
    path: "/nb/pricing",
    component: _33848c68,
    name: "pricing___nb"
  }, {
    path: "/nb/referral",
    component: _207bab07,
    name: "referral___nb",
    children: [{
      path: "banner",
      component: _a4b7ec38,
      name: "referral-banner___nb"
    }, {
      path: "text-link",
      component: _77851412,
      name: "referral-text-link___nb"
    }]
  }, {
    path: "/nb/subscribe",
    component: _4a0db1d0,
    name: "subscribe___nb"
  }, {
    path: "/nb/visualsearch",
    component: _022915d2,
    name: "visualsearch___nb"
  }, {
    path: "/nl/about",
    component: _316cc593,
    name: "about___nl"
  }, {
    path: "/nl/ai-scriptwriter",
    component: _00fbb009,
    name: "ai-scriptwriter___nl"
  }, {
    path: "/nl/audiosearch",
    component: _7b5c2004,
    name: "audiosearch___nl"
  }, {
    path: "/nl/begin",
    component: _41542caf,
    name: "begin___nl"
  }, {
    path: "/nl/brand",
    component: _5259608d,
    name: "brand___nl"
  }, {
    path: "/nl/calendar",
    component: _54d9baab,
    name: "calendar___nl"
  }, {
    path: "/nl/call-for-submission",
    component: _81e9b530,
    name: "call-for-submission___nl"
  }, {
    path: "/nl/contributors",
    component: _32b33e36,
    name: "contributors___nl"
  }, {
    path: "/nl/corporate",
    component: _0fdcd76b,
    name: "corporate___nl"
  }, {
    path: "/nl/legal",
    component: _46246bfc,
    name: "legal___nl"
  }, {
    path: "/nl/license",
    component: _6328e8c7,
    name: "license___nl"
  }, {
    path: "/nl/pricing",
    component: _33848c68,
    name: "pricing___nl"
  }, {
    path: "/nl/referral",
    component: _207bab07,
    name: "referral___nl",
    children: [{
      path: "banner",
      component: _a4b7ec38,
      name: "referral-banner___nl"
    }, {
      path: "text-link",
      component: _77851412,
      name: "referral-text-link___nl"
    }]
  }, {
    path: "/nl/subscribe",
    component: _4a0db1d0,
    name: "subscribe___nl"
  }, {
    path: "/nl/visualsearch",
    component: _022915d2,
    name: "visualsearch___nl"
  }, {
    path: "/pl/about",
    component: _316cc593,
    name: "about___pl"
  }, {
    path: "/pl/ai-scriptwriter",
    component: _00fbb009,
    name: "ai-scriptwriter___pl"
  }, {
    path: "/pl/audiosearch",
    component: _7b5c2004,
    name: "audiosearch___pl"
  }, {
    path: "/pl/begin",
    component: _41542caf,
    name: "begin___pl"
  }, {
    path: "/pl/brand",
    component: _5259608d,
    name: "brand___pl"
  }, {
    path: "/pl/calendar",
    component: _54d9baab,
    name: "calendar___pl"
  }, {
    path: "/pl/call-for-submission",
    component: _81e9b530,
    name: "call-for-submission___pl"
  }, {
    path: "/pl/contributors",
    component: _32b33e36,
    name: "contributors___pl"
  }, {
    path: "/pl/corporate",
    component: _0fdcd76b,
    name: "corporate___pl"
  }, {
    path: "/pl/legal",
    component: _46246bfc,
    name: "legal___pl"
  }, {
    path: "/pl/license",
    component: _6328e8c7,
    name: "license___pl"
  }, {
    path: "/pl/pricing",
    component: _33848c68,
    name: "pricing___pl"
  }, {
    path: "/pl/referral",
    component: _207bab07,
    name: "referral___pl",
    children: [{
      path: "banner",
      component: _a4b7ec38,
      name: "referral-banner___pl"
    }, {
      path: "text-link",
      component: _77851412,
      name: "referral-text-link___pl"
    }]
  }, {
    path: "/pl/subscribe",
    component: _4a0db1d0,
    name: "subscribe___pl"
  }, {
    path: "/pl/visualsearch",
    component: _022915d2,
    name: "visualsearch___pl"
  }, {
    path: "/pt/about",
    component: _316cc593,
    name: "about___pt"
  }, {
    path: "/pt/ai-scriptwriter",
    component: _00fbb009,
    name: "ai-scriptwriter___pt"
  }, {
    path: "/pt/audiosearch",
    component: _7b5c2004,
    name: "audiosearch___pt"
  }, {
    path: "/pt/begin",
    component: _41542caf,
    name: "begin___pt"
  }, {
    path: "/pt/brand",
    component: _5259608d,
    name: "brand___pt"
  }, {
    path: "/pt/calendar",
    component: _54d9baab,
    name: "calendar___pt"
  }, {
    path: "/pt/call-for-submission",
    component: _81e9b530,
    name: "call-for-submission___pt"
  }, {
    path: "/pt/contributors",
    component: _32b33e36,
    name: "contributors___pt"
  }, {
    path: "/pt/corporate",
    component: _0fdcd76b,
    name: "corporate___pt"
  }, {
    path: "/pt/legal",
    component: _46246bfc,
    name: "legal___pt"
  }, {
    path: "/pt/license",
    component: _6328e8c7,
    name: "license___pt"
  }, {
    path: "/pt/pricing",
    component: _33848c68,
    name: "pricing___pt"
  }, {
    path: "/pt/referral",
    component: _207bab07,
    name: "referral___pt",
    children: [{
      path: "banner",
      component: _a4b7ec38,
      name: "referral-banner___pt"
    }, {
      path: "text-link",
      component: _77851412,
      name: "referral-text-link___pt"
    }]
  }, {
    path: "/pt/subscribe",
    component: _4a0db1d0,
    name: "subscribe___pt"
  }, {
    path: "/pt/visualsearch",
    component: _022915d2,
    name: "visualsearch___pt"
  }, {
    path: "/ru/about",
    component: _316cc593,
    name: "about___ru"
  }, {
    path: "/ru/ai-scriptwriter",
    component: _00fbb009,
    name: "ai-scriptwriter___ru"
  }, {
    path: "/ru/audiosearch",
    component: _7b5c2004,
    name: "audiosearch___ru"
  }, {
    path: "/ru/begin",
    component: _41542caf,
    name: "begin___ru"
  }, {
    path: "/ru/brand",
    component: _5259608d,
    name: "brand___ru"
  }, {
    path: "/ru/calendar",
    component: _54d9baab,
    name: "calendar___ru"
  }, {
    path: "/ru/call-for-submission",
    component: _81e9b530,
    name: "call-for-submission___ru"
  }, {
    path: "/ru/contributors",
    component: _32b33e36,
    name: "contributors___ru"
  }, {
    path: "/ru/corporate",
    component: _0fdcd76b,
    name: "corporate___ru"
  }, {
    path: "/ru/legal",
    component: _46246bfc,
    name: "legal___ru"
  }, {
    path: "/ru/license",
    component: _6328e8c7,
    name: "license___ru"
  }, {
    path: "/ru/pricing",
    component: _33848c68,
    name: "pricing___ru"
  }, {
    path: "/ru/referral",
    component: _207bab07,
    name: "referral___ru",
    children: [{
      path: "banner",
      component: _a4b7ec38,
      name: "referral-banner___ru"
    }, {
      path: "text-link",
      component: _77851412,
      name: "referral-text-link___ru"
    }]
  }, {
    path: "/ru/subscribe",
    component: _4a0db1d0,
    name: "subscribe___ru"
  }, {
    path: "/ru/visualsearch",
    component: _022915d2,
    name: "visualsearch___ru"
  }, {
    path: "/sv/about",
    component: _316cc593,
    name: "about___sv"
  }, {
    path: "/sv/ai-scriptwriter",
    component: _00fbb009,
    name: "ai-scriptwriter___sv"
  }, {
    path: "/sv/audiosearch",
    component: _7b5c2004,
    name: "audiosearch___sv"
  }, {
    path: "/sv/begin",
    component: _41542caf,
    name: "begin___sv"
  }, {
    path: "/sv/brand",
    component: _5259608d,
    name: "brand___sv"
  }, {
    path: "/sv/calendar",
    component: _54d9baab,
    name: "calendar___sv"
  }, {
    path: "/sv/call-for-submission",
    component: _81e9b530,
    name: "call-for-submission___sv"
  }, {
    path: "/sv/contributors",
    component: _32b33e36,
    name: "contributors___sv"
  }, {
    path: "/sv/corporate",
    component: _0fdcd76b,
    name: "corporate___sv"
  }, {
    path: "/sv/legal",
    component: _46246bfc,
    name: "legal___sv"
  }, {
    path: "/sv/license",
    component: _6328e8c7,
    name: "license___sv"
  }, {
    path: "/sv/pricing",
    component: _33848c68,
    name: "pricing___sv"
  }, {
    path: "/sv/referral",
    component: _207bab07,
    name: "referral___sv",
    children: [{
      path: "banner",
      component: _a4b7ec38,
      name: "referral-banner___sv"
    }, {
      path: "text-link",
      component: _77851412,
      name: "referral-text-link___sv"
    }]
  }, {
    path: "/sv/subscribe",
    component: _4a0db1d0,
    name: "subscribe___sv"
  }, {
    path: "/sv/visualsearch",
    component: _022915d2,
    name: "visualsearch___sv"
  }, {
    path: "/th/about",
    component: _316cc593,
    name: "about___th"
  }, {
    path: "/th/ai-scriptwriter",
    component: _00fbb009,
    name: "ai-scriptwriter___th"
  }, {
    path: "/th/audiosearch",
    component: _7b5c2004,
    name: "audiosearch___th"
  }, {
    path: "/th/begin",
    component: _41542caf,
    name: "begin___th"
  }, {
    path: "/th/brand",
    component: _5259608d,
    name: "brand___th"
  }, {
    path: "/th/calendar",
    component: _54d9baab,
    name: "calendar___th"
  }, {
    path: "/th/call-for-submission",
    component: _81e9b530,
    name: "call-for-submission___th"
  }, {
    path: "/th/contributors",
    component: _32b33e36,
    name: "contributors___th"
  }, {
    path: "/th/corporate",
    component: _0fdcd76b,
    name: "corporate___th"
  }, {
    path: "/th/legal",
    component: _46246bfc,
    name: "legal___th"
  }, {
    path: "/th/license",
    component: _6328e8c7,
    name: "license___th"
  }, {
    path: "/th/pricing",
    component: _33848c68,
    name: "pricing___th"
  }, {
    path: "/th/referral",
    component: _207bab07,
    name: "referral___th",
    children: [{
      path: "banner",
      component: _a4b7ec38,
      name: "referral-banner___th"
    }, {
      path: "text-link",
      component: _77851412,
      name: "referral-text-link___th"
    }]
  }, {
    path: "/th/subscribe",
    component: _4a0db1d0,
    name: "subscribe___th"
  }, {
    path: "/th/visualsearch",
    component: _022915d2,
    name: "visualsearch___th"
  }, {
    path: "/tr/about",
    component: _316cc593,
    name: "about___tr"
  }, {
    path: "/tr/ai-scriptwriter",
    component: _00fbb009,
    name: "ai-scriptwriter___tr"
  }, {
    path: "/tr/audiosearch",
    component: _7b5c2004,
    name: "audiosearch___tr"
  }, {
    path: "/tr/begin",
    component: _41542caf,
    name: "begin___tr"
  }, {
    path: "/tr/brand",
    component: _5259608d,
    name: "brand___tr"
  }, {
    path: "/tr/calendar",
    component: _54d9baab,
    name: "calendar___tr"
  }, {
    path: "/tr/call-for-submission",
    component: _81e9b530,
    name: "call-for-submission___tr"
  }, {
    path: "/tr/contributors",
    component: _32b33e36,
    name: "contributors___tr"
  }, {
    path: "/tr/corporate",
    component: _0fdcd76b,
    name: "corporate___tr"
  }, {
    path: "/tr/legal",
    component: _46246bfc,
    name: "legal___tr"
  }, {
    path: "/tr/license",
    component: _6328e8c7,
    name: "license___tr"
  }, {
    path: "/tr/pricing",
    component: _33848c68,
    name: "pricing___tr"
  }, {
    path: "/tr/referral",
    component: _207bab07,
    name: "referral___tr",
    children: [{
      path: "banner",
      component: _a4b7ec38,
      name: "referral-banner___tr"
    }, {
      path: "text-link",
      component: _77851412,
      name: "referral-text-link___tr"
    }]
  }, {
    path: "/tr/subscribe",
    component: _4a0db1d0,
    name: "subscribe___tr"
  }, {
    path: "/tr/visualsearch",
    component: _022915d2,
    name: "visualsearch___tr"
  }, {
    path: "/zh-hans/about",
    component: _316cc593,
    name: "about___zh-hans"
  }, {
    path: "/zh-hans/ai-scriptwriter",
    component: _00fbb009,
    name: "ai-scriptwriter___zh-hans"
  }, {
    path: "/zh-hans/audiosearch",
    component: _7b5c2004,
    name: "audiosearch___zh-hans"
  }, {
    path: "/zh-hans/begin",
    component: _41542caf,
    name: "begin___zh-hans"
  }, {
    path: "/zh-hans/brand",
    component: _5259608d,
    name: "brand___zh-hans"
  }, {
    path: "/zh-hans/calendar",
    component: _54d9baab,
    name: "calendar___zh-hans"
  }, {
    path: "/zh-hans/call-for-submission",
    component: _81e9b530,
    name: "call-for-submission___zh-hans"
  }, {
    path: "/zh-hans/contributors",
    component: _32b33e36,
    name: "contributors___zh-hans"
  }, {
    path: "/zh-hans/corporate",
    component: _0fdcd76b,
    name: "corporate___zh-hans"
  }, {
    path: "/zh-hans/legal",
    component: _46246bfc,
    name: "legal___zh-hans"
  }, {
    path: "/zh-hans/license",
    component: _6328e8c7,
    name: "license___zh-hans"
  }, {
    path: "/zh-hans/pricing",
    component: _33848c68,
    name: "pricing___zh-hans"
  }, {
    path: "/zh-hans/referral",
    component: _207bab07,
    name: "referral___zh-hans",
    children: [{
      path: "banner",
      component: _a4b7ec38,
      name: "referral-banner___zh-hans"
    }, {
      path: "text-link",
      component: _77851412,
      name: "referral-text-link___zh-hans"
    }]
  }, {
    path: "/zh-hans/subscribe",
    component: _4a0db1d0,
    name: "subscribe___zh-hans"
  }, {
    path: "/zh-hans/visualsearch",
    component: _022915d2,
    name: "visualsearch___zh-hans"
  }, {
    path: "/zh-hant/about",
    component: _316cc593,
    name: "about___zh-hant"
  }, {
    path: "/zh-hant/ai-scriptwriter",
    component: _00fbb009,
    name: "ai-scriptwriter___zh-hant"
  }, {
    path: "/zh-hant/audiosearch",
    component: _7b5c2004,
    name: "audiosearch___zh-hant"
  }, {
    path: "/zh-hant/begin",
    component: _41542caf,
    name: "begin___zh-hant"
  }, {
    path: "/zh-hant/brand",
    component: _5259608d,
    name: "brand___zh-hant"
  }, {
    path: "/zh-hant/calendar",
    component: _54d9baab,
    name: "calendar___zh-hant"
  }, {
    path: "/zh-hant/call-for-submission",
    component: _81e9b530,
    name: "call-for-submission___zh-hant"
  }, {
    path: "/zh-hant/contributors",
    component: _32b33e36,
    name: "contributors___zh-hant"
  }, {
    path: "/zh-hant/corporate",
    component: _0fdcd76b,
    name: "corporate___zh-hant"
  }, {
    path: "/zh-hant/legal",
    component: _46246bfc,
    name: "legal___zh-hant"
  }, {
    path: "/zh-hant/license",
    component: _6328e8c7,
    name: "license___zh-hant"
  }, {
    path: "/zh-hant/pricing",
    component: _33848c68,
    name: "pricing___zh-hant"
  }, {
    path: "/zh-hant/referral",
    component: _207bab07,
    name: "referral___zh-hant",
    children: [{
      path: "banner",
      component: _a4b7ec38,
      name: "referral-banner___zh-hant"
    }, {
      path: "text-link",
      component: _77851412,
      name: "referral-text-link___zh-hant"
    }]
  }, {
    path: "/zh-hant/subscribe",
    component: _4a0db1d0,
    name: "subscribe___zh-hant"
  }, {
    path: "/zh-hant/visualsearch",
    component: _022915d2,
    name: "visualsearch___zh-hant"
  }, {
    path: "/da/call-for-submission/:channel?",
    component: _66deeec4,
    name: "call-for-submission-channel___da"
  }, {
    path: "/da/campaign/:id?",
    component: _58f47fa5,
    name: "campaign-id___da"
  }, {
    path: "/da/contributors/:username",
    component: _3172d348,
    name: "contributors-username___da"
  }, {
    path: "/da/free/:channel?",
    component: _581eef0d,
    name: "free-channel___da"
  }, {
    path: "/da/legal/:document",
    component: _3cdbf8ac,
    name: "legal-document___da"
  }, {
    path: "/de/call-for-submission/:channel?",
    component: _66deeec4,
    name: "call-for-submission-channel___de"
  }, {
    path: "/de/campaign/:id?",
    component: _58f47fa5,
    name: "campaign-id___de"
  }, {
    path: "/de/contributors/:username",
    component: _3172d348,
    name: "contributors-username___de"
  }, {
    path: "/de/free/:channel?",
    component: _581eef0d,
    name: "free-channel___de"
  }, {
    path: "/de/legal/:document",
    component: _3cdbf8ac,
    name: "legal-document___de"
  }, {
    path: "/es/call-for-submission/:channel?",
    component: _66deeec4,
    name: "call-for-submission-channel___es"
  }, {
    path: "/es/campaign/:id?",
    component: _58f47fa5,
    name: "campaign-id___es"
  }, {
    path: "/es/contributors/:username",
    component: _3172d348,
    name: "contributors-username___es"
  }, {
    path: "/es/free/:channel?",
    component: _581eef0d,
    name: "free-channel___es"
  }, {
    path: "/es/legal/:document",
    component: _3cdbf8ac,
    name: "legal-document___es"
  }, {
    path: "/fr/call-for-submission/:channel?",
    component: _66deeec4,
    name: "call-for-submission-channel___fr"
  }, {
    path: "/fr/campaign/:id?",
    component: _58f47fa5,
    name: "campaign-id___fr"
  }, {
    path: "/fr/contributors/:username",
    component: _3172d348,
    name: "contributors-username___fr"
  }, {
    path: "/fr/free/:channel?",
    component: _581eef0d,
    name: "free-channel___fr"
  }, {
    path: "/fr/legal/:document",
    component: _3cdbf8ac,
    name: "legal-document___fr"
  }, {
    path: "/hu/call-for-submission/:channel?",
    component: _66deeec4,
    name: "call-for-submission-channel___hu"
  }, {
    path: "/hu/campaign/:id?",
    component: _58f47fa5,
    name: "campaign-id___hu"
  }, {
    path: "/hu/contributors/:username",
    component: _3172d348,
    name: "contributors-username___hu"
  }, {
    path: "/hu/free/:channel?",
    component: _581eef0d,
    name: "free-channel___hu"
  }, {
    path: "/hu/legal/:document",
    component: _3cdbf8ac,
    name: "legal-document___hu"
  }, {
    path: "/it/call-for-submission/:channel?",
    component: _66deeec4,
    name: "call-for-submission-channel___it"
  }, {
    path: "/it/campaign/:id?",
    component: _58f47fa5,
    name: "campaign-id___it"
  }, {
    path: "/it/contributors/:username",
    component: _3172d348,
    name: "contributors-username___it"
  }, {
    path: "/it/free/:channel?",
    component: _581eef0d,
    name: "free-channel___it"
  }, {
    path: "/it/legal/:document",
    component: _3cdbf8ac,
    name: "legal-document___it"
  }, {
    path: "/ja/call-for-submission/:channel?",
    component: _66deeec4,
    name: "call-for-submission-channel___ja"
  }, {
    path: "/ja/campaign/:id?",
    component: _58f47fa5,
    name: "campaign-id___ja"
  }, {
    path: "/ja/contributors/:username",
    component: _3172d348,
    name: "contributors-username___ja"
  }, {
    path: "/ja/free/:channel?",
    component: _581eef0d,
    name: "free-channel___ja"
  }, {
    path: "/ja/legal/:document",
    component: _3cdbf8ac,
    name: "legal-document___ja"
  }, {
    path: "/ko/call-for-submission/:channel?",
    component: _66deeec4,
    name: "call-for-submission-channel___ko"
  }, {
    path: "/ko/campaign/:id?",
    component: _58f47fa5,
    name: "campaign-id___ko"
  }, {
    path: "/ko/contributors/:username",
    component: _3172d348,
    name: "contributors-username___ko"
  }, {
    path: "/ko/free/:channel?",
    component: _581eef0d,
    name: "free-channel___ko"
  }, {
    path: "/ko/legal/:document",
    component: _3cdbf8ac,
    name: "legal-document___ko"
  }, {
    path: "/nb/call-for-submission/:channel?",
    component: _66deeec4,
    name: "call-for-submission-channel___nb"
  }, {
    path: "/nb/campaign/:id?",
    component: _58f47fa5,
    name: "campaign-id___nb"
  }, {
    path: "/nb/contributors/:username",
    component: _3172d348,
    name: "contributors-username___nb"
  }, {
    path: "/nb/free/:channel?",
    component: _581eef0d,
    name: "free-channel___nb"
  }, {
    path: "/nb/legal/:document",
    component: _3cdbf8ac,
    name: "legal-document___nb"
  }, {
    path: "/nl/call-for-submission/:channel?",
    component: _66deeec4,
    name: "call-for-submission-channel___nl"
  }, {
    path: "/nl/campaign/:id?",
    component: _58f47fa5,
    name: "campaign-id___nl"
  }, {
    path: "/nl/contributors/:username",
    component: _3172d348,
    name: "contributors-username___nl"
  }, {
    path: "/nl/free/:channel?",
    component: _581eef0d,
    name: "free-channel___nl"
  }, {
    path: "/nl/legal/:document",
    component: _3cdbf8ac,
    name: "legal-document___nl"
  }, {
    path: "/pl/call-for-submission/:channel?",
    component: _66deeec4,
    name: "call-for-submission-channel___pl"
  }, {
    path: "/pl/campaign/:id?",
    component: _58f47fa5,
    name: "campaign-id___pl"
  }, {
    path: "/pl/contributors/:username",
    component: _3172d348,
    name: "contributors-username___pl"
  }, {
    path: "/pl/free/:channel?",
    component: _581eef0d,
    name: "free-channel___pl"
  }, {
    path: "/pl/legal/:document",
    component: _3cdbf8ac,
    name: "legal-document___pl"
  }, {
    path: "/pt/call-for-submission/:channel?",
    component: _66deeec4,
    name: "call-for-submission-channel___pt"
  }, {
    path: "/pt/campaign/:id?",
    component: _58f47fa5,
    name: "campaign-id___pt"
  }, {
    path: "/pt/contributors/:username",
    component: _3172d348,
    name: "contributors-username___pt"
  }, {
    path: "/pt/free/:channel?",
    component: _581eef0d,
    name: "free-channel___pt"
  }, {
    path: "/pt/legal/:document",
    component: _3cdbf8ac,
    name: "legal-document___pt"
  }, {
    path: "/ru/call-for-submission/:channel?",
    component: _66deeec4,
    name: "call-for-submission-channel___ru"
  }, {
    path: "/ru/campaign/:id?",
    component: _58f47fa5,
    name: "campaign-id___ru"
  }, {
    path: "/ru/contributors/:username",
    component: _3172d348,
    name: "contributors-username___ru"
  }, {
    path: "/ru/free/:channel?",
    component: _581eef0d,
    name: "free-channel___ru"
  }, {
    path: "/ru/legal/:document",
    component: _3cdbf8ac,
    name: "legal-document___ru"
  }, {
    path: "/sv/call-for-submission/:channel?",
    component: _66deeec4,
    name: "call-for-submission-channel___sv"
  }, {
    path: "/sv/campaign/:id?",
    component: _58f47fa5,
    name: "campaign-id___sv"
  }, {
    path: "/sv/contributors/:username",
    component: _3172d348,
    name: "contributors-username___sv"
  }, {
    path: "/sv/free/:channel?",
    component: _581eef0d,
    name: "free-channel___sv"
  }, {
    path: "/sv/legal/:document",
    component: _3cdbf8ac,
    name: "legal-document___sv"
  }, {
    path: "/th/call-for-submission/:channel?",
    component: _66deeec4,
    name: "call-for-submission-channel___th"
  }, {
    path: "/th/campaign/:id?",
    component: _58f47fa5,
    name: "campaign-id___th"
  }, {
    path: "/th/contributors/:username",
    component: _3172d348,
    name: "contributors-username___th"
  }, {
    path: "/th/free/:channel?",
    component: _581eef0d,
    name: "free-channel___th"
  }, {
    path: "/th/legal/:document",
    component: _3cdbf8ac,
    name: "legal-document___th"
  }, {
    path: "/tr/call-for-submission/:channel?",
    component: _66deeec4,
    name: "call-for-submission-channel___tr"
  }, {
    path: "/tr/campaign/:id?",
    component: _58f47fa5,
    name: "campaign-id___tr"
  }, {
    path: "/tr/contributors/:username",
    component: _3172d348,
    name: "contributors-username___tr"
  }, {
    path: "/tr/free/:channel?",
    component: _581eef0d,
    name: "free-channel___tr"
  }, {
    path: "/tr/legal/:document",
    component: _3cdbf8ac,
    name: "legal-document___tr"
  }, {
    path: "/zh-hans/call-for-submission/:channel?",
    component: _66deeec4,
    name: "call-for-submission-channel___zh-hans"
  }, {
    path: "/zh-hans/campaign/:id?",
    component: _58f47fa5,
    name: "campaign-id___zh-hans"
  }, {
    path: "/zh-hans/contributors/:username",
    component: _3172d348,
    name: "contributors-username___zh-hans"
  }, {
    path: "/zh-hans/free/:channel?",
    component: _581eef0d,
    name: "free-channel___zh-hans"
  }, {
    path: "/zh-hans/legal/:document",
    component: _3cdbf8ac,
    name: "legal-document___zh-hans"
  }, {
    path: "/zh-hant/call-for-submission/:channel?",
    component: _66deeec4,
    name: "call-for-submission-channel___zh-hant"
  }, {
    path: "/zh-hant/campaign/:id?",
    component: _58f47fa5,
    name: "campaign-id___zh-hant"
  }, {
    path: "/zh-hant/contributors/:username",
    component: _3172d348,
    name: "contributors-username___zh-hant"
  }, {
    path: "/zh-hant/free/:channel?",
    component: _581eef0d,
    name: "free-channel___zh-hant"
  }, {
    path: "/zh-hant/legal/:document",
    component: _3cdbf8ac,
    name: "legal-document___zh-hant"
  }, {
    path: "/call-for-submission/:channel?",
    component: _66deeec4,
    name: "call-for-submission-channel___en"
  }, {
    path: "/campaign/:id?",
    component: _58f47fa5,
    name: "campaign-id___en"
  }, {
    path: "/contributors/:username",
    component: _3172d348,
    name: "contributors-username___en"
  }, {
    path: "/da/:channel",
    component: _e08c30a4,
    name: "channel___da"
  }, {
    path: "/de/:channel",
    component: _e08c30a4,
    name: "channel___de"
  }, {
    path: "/es/:channel",
    component: _e08c30a4,
    name: "channel___es"
  }, {
    path: "/fr/:channel",
    component: _e08c30a4,
    name: "channel___fr"
  }, {
    path: "/free/:channel?",
    component: _581eef0d,
    name: "free-channel___en"
  }, {
    path: "/hu/:channel",
    component: _e08c30a4,
    name: "channel___hu"
  }, {
    path: "/it/:channel",
    component: _e08c30a4,
    name: "channel___it"
  }, {
    path: "/ja/:channel",
    component: _e08c30a4,
    name: "channel___ja"
  }, {
    path: "/ko/:channel",
    component: _e08c30a4,
    name: "channel___ko"
  }, {
    path: "/legal/:document",
    component: _3cdbf8ac,
    name: "legal-document___en"
  }, {
    path: "/nb/:channel",
    component: _e08c30a4,
    name: "channel___nb"
  }, {
    path: "/nl/:channel",
    component: _e08c30a4,
    name: "channel___nl"
  }, {
    path: "/pl/:channel",
    component: _e08c30a4,
    name: "channel___pl"
  }, {
    path: "/pt/:channel",
    component: _e08c30a4,
    name: "channel___pt"
  }, {
    path: "/ru/:channel",
    component: _e08c30a4,
    name: "channel___ru"
  }, {
    path: "/sv/:channel",
    component: _e08c30a4,
    name: "channel___sv"
  }, {
    path: "/th/:channel",
    component: _e08c30a4,
    name: "channel___th"
  }, {
    path: "/tr/:channel",
    component: _e08c30a4,
    name: "channel___tr"
  }, {
    path: "/zh-hans/:channel",
    component: _e08c30a4,
    name: "channel___zh-hans"
  }, {
    path: "/zh-hant/:channel",
    component: _e08c30a4,
    name: "channel___zh-hant"
  }, {
    path: "/",
    component: _5124a058,
    name: "index___en"
  }, {
    path: "/:channel",
    component: _e08c30a4,
    name: "channel___en"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
